.presentation{
    height: 840px;
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;
    background-color: $main-color;
}
.content-presentation{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.presentation-left{
    height: 450px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

}
.block-text{
    width:80%;
    height: 100%;
    background-color: $second-color ;
    padding: 2%;
    border-radius: 5px;
    display: flex;
   text-align: left;
    justify-content: center;
    flex-direction: column;
    box-shadow: $shadowContainer;
    h3{
        margin-bottom: 15px;
    }
    p{
       margin-top: 10px;
       margin-bottom: 10px;
    }
}

.presentation-right{
    height: 450px;
    width: 50%;
    img{
        height: 450px;
        width: 450px;
        object-fit: cover;
        border-radius: 5px;
    }
}


@media screen and (max-width:1024px){

    .presentation{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        h2{
            margin-top: 25px;
        }
    }

    .content-presentation{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
    }
    
    .presentation-left{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    .block-text{
        height: 300px;
        width: 100%;
    }
    
    .presentation-right{
        height: 300px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height:300px;
            width: 600px;
            object-fit: cover;
            border-radius: 5px;
        }
    }
    
}


@media screen and (max-width:650px){

    .presentation{
        height: 1040px;
        width: 100%;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: space-evenly;
        background-color: $main-color;
    }


    .presentation{
        h2{
            margin-top: 35px;
        }
    }

    .block-text{
        height: 400px;
        width: 100%;
        padding: 15px;
    }

    .presentation-right{
        img{
            height:400px;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }
}


@media screen and (max-width:426px){
    .presentation{
        h2{
            margin-top: 35px;
        }
    }

    .presentation-right{
        img{
            height:300px;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }
}