.temoignages{
    height: 840px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: $main-color;
}

.phrase-intro-temoignages{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    height: 120px;
}

.temoignages-display{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 2%;
}

.temoin-text{
    background-color: $second-color;
    padding: 2%;
    border-radius: 5px;
    box-shadow: $shadowContainer;
    p{
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px;
    }
    h4{
        text-align: left;
        font-size: 20px;
    }
}


@media screen and (max-width:1024px){
    .temoin-text{
        p{
            font-size: 14px;
        }
        h4{
            font-size: 16px;
        }
    }
}

@media screen and (max-width:426px){

    .temoignages-display{
        padding: 0;
        width: 90%;
    }
    .temoin-text{
        padding: 4%;
        p{
            font-size: 12px;
        }
        h4{
            font-size: 14px;
        }
    }
}