.calltoaction{
    height: 840px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: $second-color;
    h4{
        font-weight: normal;
        font-weight: 26px;
    }
}
.calltoaction-content{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
}
.presentation-right-calltoaction{
    height: 450px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .adresse{
        h4{
            margin-bottom: 20px;
            text-decoration: underline;
            font-size: 24px;
        }
        address{
            font-style: normal;
            font-size: 18px;
            font-weight: lighter;
        }
    }
    iframe{
        height: 450px;
        width: 75%;
        object-fit: cover;
        border-radius: 5px;
        border: none;
        box-shadow: $shadowContainer;
    }
}

.presentation-left-calltoaction{
    height: 450px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}


.block-text-calltoaction{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .horaires{
        h4{
            margin-bottom: 20px;
            text-decoration: underline;
            font-size: 24px;
        }
    }
}

.coordonnes-card{
    background-color:$main-color;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    height: 450px;
    object-fit: cover;
    border-radius: 5px;
    border: none;
    box-shadow: $shadowContainer;
    width: 75%;
    h4{
        font-size: 18px;
    }
}
.phone-mail{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


@media screen and (max-width:1024px){
    .calltoaction-content{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
        height: 540px;
    }
    .presentation-right-calltoaction{
        height: 220px;
        width: 90%;
        
        .adresse{
            width: 40%;
            h4{
                margin-bottom: 20px;
            }
            address{
                font-size: 14px;
            }
        }
        iframe{
            height: 220px;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            border: none;
        }
    }
    
    .presentation-left-calltoaction{
        height: 220px;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    
    .block-text-calltoaction{
        display: flex;
        height: 220px;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .horaires{
            width: 40%;
            h4{
                margin-bottom: 20px;
                text-decoration: underline;
            }
        }
    }

    .coordonnes-card{
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        object-fit: cover;
        border-radius: 5px;
        border: none;
        width: 100%;
        h4{
            color: $font-color;
            padding: 2%;
            font-size: 16px;
        }
    }
    .phone-mail{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
}

@media screen and (max-width:426px){
   
    .calltoaction-content{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
        height: 540px;
    }
    .presentation-right-calltoaction{
        height: 220px;
        width: 90%;
        
        .adresse{
            width: 40%;
            h4{
                margin-bottom: 20px;
            }
            address{
                font-size: 14px;
            }
        }
        iframe{
            height: 220px;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            border: none;
        }
    }
    
    .presentation-left-calltoaction{
        height: 220px;
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    
    .block-text-calltoaction{
        display: flex;
        height: 220px;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .horaires{
            width: 40%;
            h4{
                margin-bottom: 20px;
                text-decoration: underline;
            }
        }
    }

    .coordonnes-card{
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        object-fit: cover;
        border-radius: 5px;
        border: none;
        width: 100%;
        h4{
            color: $font-color;
            padding: 2%;
            font-size: 16px;
        }
    }
    .phone-mail{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2%;
    }
    
}