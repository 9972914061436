.techniques{
    height: 1040px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: $second-color;
}

.techniques-display{
    display: grid;
    width: 95%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10%;
    row-gap: 8%;
    justify-self: stretch;
    align-items: center;
}

.reiki-massage-magnetisme{
    width: 100%;
    height: 850px;
    border-radius: 1px;
    overflow: hidden; 
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    background-color: $main-color;
    border-radius: 5px;
    padding: 1%;
    box-shadow: $shadowContainer;
    img{
        width: 100%;
        height: 300px;
        align-items: stretch;
        justify-content: stretch;
        object-fit:cover;  
        border-radius: 5px;
        padding: 2%;
    }
}

.description-technique{
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 550px;
    h3{
        margin-bottom: 10px;
    }
    p{
        padding: 2%;
        height: 280px;
    
    }
    a{
        text-decoration: none;
        color: $second-color;
        font-size: 14px ;
        align-items: center;
        justify-content:center;
        display: flex;
        font-weight: lighter;
        cursor: pointer;
        background-color: $calltoactioncolor;
        width: 90%;
        height: 40px;
        border-radius: 5px;
        box-shadow: $shadowButton;
    }
}
@media screen and (max-width:1440px){
    .techniques{
        height: 1040px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: $second-color;
    }
    
    .techniques-display{
        display: grid;
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 10%;
        row-gap: 8%;
        justify-self: stretch;
        align-items: center;
    }
    
    .reiki-massage-magnetisme{
        width: 100%;
        height: 850px;
        border-radius: 1px;
        overflow: hidden; 
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        background-color: $main-color;
        border-radius: 5px;
        padding: 1%;
        box-shadow: $shadowContainer;
        img{
            width: 100%;
            height: 300px;
            align-items: stretch;
            justify-content: stretch;
            object-fit:cover;  
            border-radius: 5px;
            padding: 2%;
        }
    }
    
    .description-technique{
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 500px;
        h3{
            margin-bottom: 10px;
        }
        p{
            padding: 2%;
            height: 280px;
        
        }
        a{
            text-decoration: none;
            color: $second-color;
            font-size: 14px ;
            align-items: center;
            justify-content:center;
            display: flex;
            font-weight: lighter;
            cursor: pointer;
            background-color: $calltoactioncolor;
            width: 90%;
            height: 40px;
            border-radius: 5px;
            box-shadow: $shadowButton;
        }
    }
}

@media screen and (max-width:1140px){
    .techniques{
        height: 1040px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: $second-color;
    }
    
    .techniques-display{
        display: grid;
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 10%;
        row-gap: 8%;
        justify-self: stretch;
        align-items: center;
    }
    
    .reiki-massage-magnetisme{
        width: 100%;
        height: 850px;
        border-radius: 1px;
        overflow: hidden; 
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        background-color: $main-color;
        border-radius: 5px;
        padding: 1%;
        box-shadow: $shadowContainer;
        img{
            width: 100%;
            height: 250px;
            align-items: stretch;
            justify-content: stretch;
            object-fit:cover;  
            border-radius: 5px;
            padding: 2%;
        }
    }
    
    .description-technique{
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 550px;
        h3{
            margin-bottom: 10px;
        }
        p{
            padding: 2%;
            height: auto;
        
        }
        a{
            text-decoration: none;
            color: $second-color;
            font-size: 14px ;
            align-items: center;
            justify-content:center;
            display: flex;
            font-weight: lighter;
            cursor: pointer;
            background-color: $calltoactioncolor;
            width: 90%;
            height: 40px;
            border-radius: 5px;
            box-shadow: $shadowButton;
        }
    }
}


@media screen and (max-width:1024px){
    .techniques{
        height: 1200px;
    }
    .techniques-display{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        height: auto;
    }

    .reiki-massage-magnetisme{
        width: 90%;
        height: 350px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: $main-color;
        border-radius: 5px;
        padding: 15px;
     
        img{
            width: 200px;
            height: auto;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 0%
        }
        p{
            padding: 2%;
        }
    }
 
    .description-technique{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 60%;
        height: 100%;
        h3{
            margin-bottom: 0px;
        }
        p{
            padding: 0%;
        }
        a{
            height: 40px;
            width: 50%;
            font-size: 12px;
        }
    }
}

@media screen and (max-width:660px){
    .techniques{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: $second-color;
    }


    .techniques-display{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
    }

    .reiki-massage-magnetisme{
        width: 90%;
        height: 490px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background-color: $main-color;
        border-radius: 5px;
        padding: 15px;
        margin-top: 25px;
        margin-bottom: 25px;
        img{
            width: 100%;
            height: 200px;
            align-items: center;
            justify-content: center;
            border-radius: 5px 5px 0px 0px;
            padding: 0%
        }
        p{
            padding: 2%;
            font-size: 14px;
        }
    }
 
    .description-technique{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 68%;
        height: 280px;
        h3{
            margin-bottom: 0px;
            font-size: 14px;
        }
        p{
            padding: 1%;
            font-size: 12px;
        }
        a{
            width: 100%;
            height: 40px;
        }
    }
}

@media screen and (max-width:426px){

    .techniques{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: $second-color;
        h2{
            margin-top: 20px;
        }
    }


    .techniques-display{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;
        height: auto;
    }

    .reiki-massage-magnetisme{
        width: 90%;
        height: 600px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background-color: $main-color;
        border-radius: 5px;
        padding: 1%;
        margin-top: 25px;
        margin-bottom: 25px;
        img{
            width: 100%;
            height: 200px;
            align-items: center;
            justify-content: center;
            border-radius: 5px 5px 0px 0px;
            padding: 0%
        }
        p{
            padding: 2%;
            font-size: 14px;
        }
    }
 
    .description-technique{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 95%;
        height: 400px;
        h3{
            margin-bottom: 0px;
            font-size: 14px;
        }
        p{
            padding: 1%;
            font-size: 12px;
            height: auto;
        }
        a{
            width: 100%;
            height: 40px;
        }
    }
}