.accroche{
    height: 840px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $second-color;
    flex-direction: column;
}

.accroche-title{
    width: 100%;
    height: 70px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.block-text-accroche{
    display: flex;
    //align-items: center;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    width:80%;
    height: 650px;
    background-color: $main-color ;
    padding: 4%;
    border-radius: 5px;
    box-shadow: $shadowContainer;
    h3{
        margin-top: 10px;
        margin-bottom: 0px;
    }
    p{
        margin-bottom: 10px;
    }
}

@media screen and (max-width:1024px){
    .accroche{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $second-color;
    }
    
    .block-text-accroche{
        display: flex;
        justify-content: space-evenly;
        width:80%;
        height: 650px;
    }
}

@media screen and (max-width:650px){
    .accroche{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $second-color;
        height: 1140px;
    
    }
    
    .block-text-accroche{
        display: flex;
        justify-content: space-evenly;
        width:80%;
        height: 950px;
        padding:15px;
    }
}

@media screen and (max-width:426px){

    .accroche{
        height: 1200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $second-color;
        flex-direction: column;
    }
    
    .block-text-accroche{
        width:90%;
        height: 950px;
    }
}

@media screen and (max-width:260px){

    .accroche{
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $second-color;
        flex-direction: column;
    }
    
    .block-text-accroche{
        width:90%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}