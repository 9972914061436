.footer{
    height: 200px;
    background-color: $calltoactioncolor;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.footer-interactions{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.link-display{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    align-items: center;
    a{
        text-decoration: none;
        color:$second-color;
        margin-right: 25px;
    }
}


.linkgoup{
    text-decoration: none;
}
.go-up{
    display: flex;
    flex-direction: column;
    width: auto;
    i{
        color: $second-color;
    }
    p{
        text-decoration: none;
        color:$second-color;
        font-size: 16px;
    }  
}

@media screen and (max-width:1024px){
    .footer{
        height: 100px;
    }

    .footer-interactions{
       a{
           font-size: 12px;
       }
    }

    .link-display{
        width:auto;
        a{
           margin-right: 15px;
        }
    }

    .go-up{
        p{
            font-size: 12px;
        }  
    }
}