.headbanner{
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 10000;
    background-color: #F6F5F1;
    box-shadow: 1px 1px 3px 0px #9c9c9c38;
}

.logo{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    img{
        height: 55px;
        object-fit: contain;
    }
}

.hamburger-menu{
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
    cursor: pointer;
    margin-right: 20px;
    .slice1{
        width: 32px;
        height: 2px;
        background-color: black;
    }

    .slice2{
        width: 32px;
        height: 2px;
        background-color: black;
    }

    .slice3{
        width: 32px;
        height: 2px;
        background-color: black;
    }
}


.slice-1{
    background-color: black;
    width: 32px;
    height: 2px;
    transform: rotate(45deg); 
    position: absolute;
}

.slice-2{
    background-color: black;
    width: 32px;
    height: 2px;
    transform: rotate(-45deg); 
    position: absolute;
}

.nav-open{
    background-color: $calltoactioncolor;
    display: flex;
    flex-direction: column;
    height: 85vh;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 35%;
    z-index: 100000;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 1px 1px 3px 0px #9c9c9c38;
}

.nav-open-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 2%;
    a{
        text-decoration: none;
        color: $second-color;
        font-size: 32px;
        cursor: pointer;
        font-weight: lighter;
    }
}

@media screen and (max-width:1024px){
    .headbanner{
        height: 65px;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo{
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin-left: 15px;
        .logo-display{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1%;
        }
        img{
            height: 55px;
            object-fit: contain;
        }
    }
    
    .hamburger-menu{
        height: 40px;
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0;
        cursor: pointer;
        margin-right: 20px;
        .slice1{
            width: 32px;
            height: 2px;
        }
    
        .slice2{
            width: 32px;
            height: 2px;
        }
    
        .slice3{
            width: 32px;
            height: 2px;
        }
    }

    
    .slice-1{
        width: 32px;
        height: 2px;

    }
    
    .slice-2{
        width: 32px;
        height: 2px;
    }
    
    .nav-open{
        background-color: $calltoactioncolor;
        display: flex;
        flex-direction: column;
        height: 85vh;
        right: 0;
        bottom: 0;
        position: fixed;
        width: 60%;
        z-index: 100000;
        border-radius: 5px 0px 0px 5px;
        box-shadow: 1px 1px 3px 0px #9c9c9c38;
    }
    
    .nav-open-body{
        a{
            font-size: 24px;
        }
    }
}


@media screen and (max-width:426px){
    .headbanner{
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: space-between;
        position: fixed;
        z-index: 10000;
        background-color: #F6F5F1;
        box-shadow: 1px 1px 3px 0px #9c9c9c38;
    }
    
    .logo{
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-left: 15px;
        .logo-display{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1%;
        }
        img{
            height: 45px;
            object-fit: contain;
        }
    }
    
    .hamburger-menu{
        height: 40px;

        .slice1{
            width: 32px;
            height: 2px;
            background-color: black;
        }
    
        .slice2{
            width: 32px;
            height: 2px;
            background-color: black;
        }
    
        .slice3{
            width: 32px;
            height: 2px;
            background-color: black;
        }
    }

    
    .slice-1{
        background-color: black;
        width: 32px;
        height: 2px;
        transform: rotate(45deg); 
        position: absolute;
    }
    
    .slice-2{
        background-color: black;
        width: 32px;
        height: 2px;
        transform: rotate(-45deg); 
        position: absolute;
    }
    
    .nav-open{
        background-color: $calltoactioncolor;
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 100000;
        border-radius: 5px 0px 0px 5px;
        box-shadow: 1px 1px 3px 0px #9c9c9c38;
        width: 75%;
    }
    .nav-open-body{
        a{
            font-size: 16px;
        }
    }

}
