*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

$main-color:#E9E2D9;
$second-color:#F6F5F1;
$font-color: #2c2c2c;
$calltoactioncolor : #3C6184;
$shadowContainer : 5px 3px 0px 0px #e9e2d954;
$shadowButton : 5px 3px 0px 0px #3c61842b;

html,body{
    background-color: $main-color;
    font-family: 'Archivo Narrow', sans-serif;
    //color: rgb(82, 82, 82);
    color: $calltoactioncolor;

    height: auto;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}


h1{
    font-size: 38px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

h2{
    font-size: 30px;
    font-weight: 400;
}
h3{
    font-size: 22px;
    font-weight: 400;
}
h4{
    font-size: 22px;
}

p{
    font-size: 18px;
    font-weight: lighter;
}

li{
    font-size: 28px;
    font-weight: lighter;
    list-style-type: none;
}
strong{
    font-size: 18px;
    font-weight: lighter;
}
address{
    font-size: 18px;
    font-weight: lighter;
    font-style: normal;
}

@media screen and (max-width:1450px){
    h1{
        font-size: 32px;
    }
    h2{
        font-size: 26px;
    }
    h3{
        font-size: 20px;
    }
    h4{
        font-size: 18px;
    }
    p{
        font-size: 16px;
        font-weight: lighter;
    }
    
    li{
        font-size: 14px;
        font-weight: lighter;
        list-style-type: none;
    }

    strong{
        font-size: 16px;
        font-weight: lighter;
    }
    address{
        font-size: 16px;
        font-weight: lighter;
    }
}


@media screen and (max-width:1024px){
    h1{
        font-size: 32px;
    }
    
    h2{
        font-size: 22px;
    }
    h3{
        font-size: 18px;
    }
    h4{
        font-size: 18px;
    }
    
    p{
        font-size: 14px;
        font-weight: lighter;
    }
    
    li{
        font-size: 12px;
        font-weight: lighter;
        list-style-type: none;
    }
    
    strong{
        font-size: 14px;
        font-weight: lighter;
    }
    address{
        font-size: 14px;
        font-weight: lighter;
    }
}
  
@media screen and (max-width:426px){
    h1{
        font-size: 22px;
    }
    
    h2{
        font-size: 18px;
    }
    h3,h4{
        font-size: 14px;
    }
    
    p{
        font-size: 12px;
        font-weight: lighter;
    }
    
    li{
        font-size: 12px;
        font-weight: lighter;
        list-style-type: none;
    }
    a{
        font-size: 12px;
    }
    address{
        font-size: 12px;
        font-weight: lighter;
    }
}