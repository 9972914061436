.hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    min-height: 850px;
}

.top-hero{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 600px;
}


.right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 500px;
    img{
        width: 500px;
        height: 500px;
        object-fit: cover;  
        position: relative;
        border-radius: 5px;
        box-shadow: 5px 3px 0px 0px #cacaca80;
    }
}


.left{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 500px;
}
.infos-display{
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 500px;
    justify-content: space-between;
    text-align: left;
    border-radius: 5px;
    position: relative;
    h2{
        display: flex;
    }
    .content-infos-display-intro{
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-style: italic;
    }
}



.action-button-contact{
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    
}
.contact-button{
    width: 100%;
    height: 50px;
    background-color: $calltoactioncolor;
    text-decoration: none;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: $second-color;
    border-radius: 5px;
    box-shadow: $shadowButton;
}


#scroll-wrapper {
    position: absolute;
    display: table;
    width: 100%;
    height: 20px;
    z-index: 20;
    pointer-events: none;
    left: 0;
    bottom: 0;
    //background-color: red;
}

#scroll-wrapper-inner {
    display: table-cell;
    vertical-align: bottom;
    width: 100%;
    height: 100%;
}

#scroll-down {
    display: block;
    position: relative;
    padding-top: 79px;
    text-align: center;
}

#scroll-title {
    display: block;
    font-weight: 600;
    letter-spacing: 0.2em;
    text-align: center;
    margin-bottom: 45px;
}
#scroll-down::before {
    -webkit-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    -moz-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    -o-animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    animation: elasticus 2.9s cubic-bezier(1, 0, 0, 1) infinite;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 45px;
    background: $calltoactioncolor;
    content: ' ';
}
@-webkit-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}
@-moz-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}
@-o-keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}
@keyframes elasticus {
    0% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
    50% {
        -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -o-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    50.1% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    100% {
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        -moz-transform-origin: 0% 100%;
        -o-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
        -moz-transform: scale(1, 0);
        -o-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

@media screen and (max-width:1450px){

    .right{
        z-index: 1;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 500px;
        img{
            width: 500px;
            height: 500px;
            object-fit: cover;  
            position: relative;
            border-radius: 5px;
            box-shadow: 5px 3px 0px 0px #cacaca80;
            z-index: 5;
        }
    }
    
    
    .left{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 500px;
        z-index: 5;
    }
    .infos-display{
        height: 500px;
        width: 500px;
        display: flex;
    
    }
    
    .action-button-contact{
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        
    }
    .contact-button{
        width: 100%;
        height: 50px;
        background-color: $calltoactioncolor;
        text-decoration: none;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        color: $second-color;
        border-radius: 5px;
        box-shadow: $shadowButton;
    }
}


@media screen and (max-width:1024px){
    .hero{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 980px;
    }
    
    .top-hero{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 800px;
    }
    
    .left{
        width: 90%;
        display: flex;
        align-items: center;
        text-align: center;
        height: 450px;
        z-index: 1;
        position: relative;
    }

    .infos-display{
        flex-direction: column;
        align-items: center;
        height: 100%;
        //justify-content: center;
        p{
            align-items: center;
            text-align: center;
        }
    }

    .title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .right{
        z-index: 1;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 200px;
        img{
            width: 100%;
            height: 200px;
            object-fit: cover;  
            position: relative;
            right: 0px;
            border-radius: 5px;
        }
    }

    .contact-button{
        width: 90%;
        height: 45px;
    }
    
    #scroll-wrapper {
        width: 100%;
        height: 20px;
        display: none;
    }
    
    #scroll-wrapper-inner {
        display: table-cell;
        vertical-align: bottom;
        width: 100%;
        height: 50px;
    }
    
    #scroll-down {
        display: block;
        position: relative;
        padding-top: 10px;
        text-align: center;
    }
    
    #scroll-title {
        display: block;
        font-weight: 600;
        letter-spacing: 0.2em;
        text-align: center;
        margin-bottom: 25px;
    }
    #scroll-down::before {
        width: 2px;
        height: 15px;
    }
    
}

@media screen and (max-width:426px){
    
    .hero{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        width: 100%;
        align-items: center;
        height: 800px;
    }
    
    .top-hero{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 800px;
    }
    
}
